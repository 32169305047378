import utils from "../utils";
import env from '../env';
import { isMin } from '../lib/css-emotion'

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md()
}

export function resourcePath(url){
  if (!url) return null;
  if (url && url.indexOf('/assets/') === 0) return url;
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export function fullUrl(url){
  return utils.url.isExternalLink(url)? url : env.baseOrigin + url;
}

export const isNZ = env.locale === 'nz';

let abc = {};
export function showAddCartMsg(msg = "Added to Cart"){
  const existingElemDiv = document.getElementById('addCartMsg');
  const elemDiv = document.createElement('div');
  elemDiv.id = "addCartMsg";
  elemDiv.innerText = msg;
  elemDiv.style.cssText = `
    position: fixed;
    top: 20%;
    left: 0px;
    z-index: 9999;
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background-color: rgb(37, 47, 62);
    font-weight: 700;
    color: white;
    border-radius: 10px;
    left: 50%;
    font-size:1.5rem;
    transform: translateX(-50%);
  `;

  if(existingElemDiv){
    clearTimeout(abc);
    document.body.removeChild(existingElemDiv);
    setTimeout(() => {
      document.body.appendChild(elemDiv);
    }, 500);
    abc = setTimeout(() => {
      const existingElemDiv_s = document.getElementById('addCartMsg');
      if(existingElemDiv_s){
        document.body.removeChild(existingElemDiv_s);
      }
    }, 4500);

  }
  else{
    document.body.appendChild(elemDiv);
    abc = setTimeout(() => {
      const existingElemDiv_s = document.getElementById('addCartMsg');
      if(existingElemDiv_s){
        document.body.removeChild(existingElemDiv_s);
      }
    }, 4000);
  }
}