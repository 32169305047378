/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './orderItemStyle';
import React from 'react';

import { Button, ErrorMessage } from '../../../components';
import utils from '../../../utils';

import { CartSummary } from '../cartSummary/summary';

export function OrderItems({ cart, form, moveNext, post, remove }) {
  //const model = form.model;

  const items = form.findControl('items').controls;
  const render = (name, parent = null) => form.renderControl(name, parent);
  const checkMinimumQtyList = cart.checkMinimumQtyList;

  function itemAmount(item, orderItem) {
    const val = item.getValue()
    return (val.quantity * (orderItem.unitPrice * 100)) / 100;
  }

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    })
  }

  function ItemVariantSummary({ orderItem }) {
    if (!orderItem.variantOptionsSerialised) return null;
    const options = JSON.parse(orderItem.variantOptionsSerialised);
    return <div>{
      options
        .map(x =>
          <span>
            <span css={style.optionName}>{x.Name}:</span>
            <span css={style.optionValue}>{x.Value}</span>
          </span>
        )
        .reduce((acc, x) => acc === null ? x : <React.Fragment>{acc}, {x}</React.Fragment>, null)
    }</div>
  }

  const orderItems = cart.orderItems.filter(x => x.orderItemType === 0);
  const taxLines = cart.orderItems.filter(x => x.orderItemType === 1);

  return <div>
    <div className="row" >
      <div className="col-md-9">
        {
          checkMinimumQtyList.length > 0 &&
          <GetCheckMinimumQtyList list={checkMinimumQtyList} />
        }
        <form css={style.items}>
          {orderItems.map((orderItem, index) => <div id={`order__item__index__id__${orderItem.orderItemId}`} key={orderItem.orderItemId} css={style.item}>

            <div className="row">
              <div className="col-sm-2">
                <img src={orderItem.thumbnailUrl} alt="immmm" style={{ width: '100%' }} />
              </div>

              <div className="col-sm-5">
                <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
                <div>
                  <b>{utils.money.formatMoney(((orderItem.unitPrice * 100) / 100))} (Exc Tax)</b>
                  {
                    orderItem.unitIncTaxPrice && orderItem.unitIncTaxPrice > 0 &&
                    <span style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}>{utils.money.formatMoney(((orderItem.unitIncTaxPrice * 100) / 100))} (Inc Tax)</span>
                  }
                </div>
                <ItemVariantSummary orderItem={orderItem} />
              </div>

              <div className="col-sm-2" onMouseEnter={() => {
                const i = document.querySelector(`#order__item__index__id__${orderItem.orderItemId} input`);
                if (i) {
                  i.focus();
                }
              }} onMouseLeave={() => {
                const i = document.querySelector(`#order__item__index__id__${orderItem.orderItemId} input`);
                if (i) {
                  i.blur();
                }
              }}>
                Qty: {render('quantity', items[index])}
              </div>

              <div className="col-sm-3" css={style.remove}>
                <b>{utils.money.formatMoney(itemAmount(items[index], orderItem))} </b> <span style={{ display: 'inline-block', fontSize: '0.8rem', marginBottom: '0.5rem' }}> (Exc Tax) </span>
                <Button btnStyle="outline-dark" className="btn-sm" onClick={(e) => remove(e, index)} status={form.status}>Remove</Button>
              </div>

            </div>

          </div>)}
          {taxLines.map((orderItem, index) => <div key={orderItem.orderItemId} css={style.item}>
            <div css={style.product}>
              Tax: {orderItem.itemName}
            </div>
            <div css={style.details}>
              <div css={style.qty}>

              </div>
              <div css={style.amount}>
                <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>

              </div>
              <div css={style.remove}>

              </div>
            </div>
          </div>)}

        </form>

      </div>
      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>
      <div className="col-md-9 form__actions" style={{ textAlign: 'center' }}>
        <Button onClick={next} status={form.status} checkDisabled={checkMinimumQtyList.length > 0 ? true : false}>Next</Button>
        <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
      </div>
      {
        checkMinimumQtyList.length > 0 &&
        <div className='col-12' style={{ marginTop: '1rem' }}>
          <GetCheckMinimumQtyList list={checkMinimumQtyList} />
        </div>
      }
    </div>
  </div>
}

function GetCheckMinimumQtyList({ list }) {
  return (
    <div css={style.check__minimum__qty__list} className="check__minimum__qty__list">
      {
        list.map((item, index) =>
          <div key={index} css={style.check__minimum__qty__item} className="check__minimum__qty__item">
            {`* Minimum quantity of ${item.minimumQty} items not met for ${item.productName}`}
          </div>)
      }
    </div>
  )
}