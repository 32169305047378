const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"almaq-refined",serif',

  primaryColor: '#061f5c',
  fontColor: '#404040',
  bgColor: '#ebebeb',
  darkGrayColor : '#1b2330',

  ...overloading
}

export default variables;