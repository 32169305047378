import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import env from 'env';
import { ErrorPopup } from '../site/members/error/errorPopup';
import { useRouter, SiteLink, usePost, Loading } from '../components';
import { useAuth } from 'auth';
import { MenuLoader } from './menuLoader';
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { MdPayment, MdAssignment, MdShoppingCart, MdPassword, MdLogout } from "react-icons/md";
import { useStore } from '../store';

const DashboardLayout = ({ children, ...rest }) => {
  const isCancelled = React.useRef(false);
  const [sessionChecked, setSessionChecked] = React.useState(false);
  const router = useRouter();
  const [menuOpen, setMenuOpen] = React.useState(() => {
    const w = window.innerWidth;
    if (w <= 500) {
      return false;
    }
    return true;
  });
  const { navigate, pathname, query } = router;
  const clubId = query.clubId || '';
  
  const auth = useAuth();
  //const get = useGet();
  const post = usePost();
  const { state } = useStore();
  
  const menu = [
    {
      title: 'ECOMMERCE', type: 'main', class: 'main', items: [
        { title: 'ORDERS', link: '/member/order-history', class: (auth.authData.memberInfo && auth.authData.memberInfo.memberType === 99) ? 'sub admin' : 'sub', type: 'sub', icon: <MdPayment /> },
        { title: 'PRODUCTS', link: (auth.authData.memberInfo && auth.authData.memberInfo.memberType === 99) ? '/member/admin/products' : '/member/products', class: 'sub', type: 'sub', icon: <MdAssignment /> },
        { title: 'CART', link: '/member/cart', class: 'sub', type: 'sub', icon: <MdShoppingCart /> }
      ]
    },
    {
      title: 'PROFILE', type: 'main', class: 'main', items: [
        { title: 'CHANGE PASSWORD', link: '/member/changePassword', class: (auth.authData.memberInfo && auth.authData.memberInfo.memberType === 99) ? 'sub admin' : 'sub', type: 'sub', icon: <MdPassword /> },
        { title: 'LOG OUT', link: '', onclickFun: signout, class: '', type: 'sub-function', icon: <MdLogout /> },
      ]
    }
  ];

  const sideMenu = <div className={`side_panel`}>
    {
      auth.authData.memberInfo &&
      <div className='display__name'>
        {
          auth.authData.memberInfo.memberType === 99 &&
          <div className='admin__text'> ADMIN VIEW </div>
        }
        <GetWelcomText text = {auth.authData.memberInfo.displayName}/>
        {/* <h4> {auth.authData.memberInfo.displayName} </h4> */}
      </div>
    }
    <section className="sideBarSection">
      {
        menu.map((item, index) =>
          <div key={index} className="menu-item">
            <div className="menu-item-inner">
              <div className="menu-item-title">{item.title}</div>
              {item.items.map((subItem, subItemIndex) =>
                <div className={`menu-item-sub ${subItem.class} ${pathname === subItem.link ? 'selected' : ''}`} key={subItemIndex} >
                  {subItem.icon && subItem.icon}
                  {
                    (subItem.type === 'sub-function') ?
                      <span className='button' style={{ cursor: 'pointer' }} onClick={subItem.onclickFun}> {subItem.title} </span> :
                      <SiteLink to={subItem.link}> {subItem.title} </SiteLink>
                  }
                </div>)}
            </div>
          </div>
        )
      }
    </section>
  </div>;

  React.useEffect(() => {
    const checkAuth = async () => {
      if (!isCancelled.current) {
        const loggedIn = await auth.loggedIn();
        if (!loggedIn) {
          navigate('/login');
        } else {

          if (auth.authData.memberInfo) {
            setSessionChecked(true);
          }
          else {
            post.send(env.apiBase + `/api/dashboard/getdashboard`,  {clubId : clubId});
          }

        }
      }
    };
    checkAuth();
    
    return () => {
      isCancelled.current = true;
    };

    //eslint-disable-next-line
  }, [auth, navigate]);

  React.useEffect(() => {

    if (auth.authData.memberInfo) {
      return;
    }

    if (sessionChecked === true && data) {
      auth.setMemberInfo(data.memberInfo);
    }
    //eslint-disable-next-line
  }, [sessionChecked]);

  if (post.done()) {
    const _data = post.response;
    if(clubId === '' && _data.memberInfo.memberType === 99){
      //router.push('/member/admin/dashboard/' + _data.memberInfo.clubId);
      window.location.href = '/member/admin/dashboard/' + _data.memberInfo.clubId;
      //return;
    }
    else{
      post.reset();
      setSessionChecked(true);
    }
  }
  else if (post.hasErrors() && sessionChecked === false) {
    setSessionChecked(true);
  }

  const data = post.response;

  function signout(e) {
    e.preventDefault();
    e.stopPropagation();

    auth.signout().then(user => {
      router.push('/login')
    }).catch(error => {
      console.log(error)
    });

  }

  if (!sessionChecked) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  else if (post.hasErrors()) {
    let errors = post.errors;
    let title = 'Loading Error';
    if (post.containsErrorMessage('inactive account')) {
      title = 'Account Disabled';
      errors = 'This account is inactive or temporarily disabled.';
      //auth.signout();
    }

    return <ErrorPopup title={title} errors={errors} />;
  }
  else {
    return (
      <Fragment>
        <div className='menu-icon'> <AiOutlineMenuUnfold onClick={() => {
          setMenuOpen(!menuOpen);
        }} /></div>
        <div className='top-cart-icon'>
          <SiteLink to="/member/cart">
            <MdShoppingCart />
            <span className="cart-count"> {state.cartInfo ? state.cartInfo : 0} </span>
          </SiteLink>
        </div>
        <div className={`mobile-side-menu-container ${menuOpen ? "open" : "close"}`}>
          {sideMenu}
        </div>
        <div className={`dash__board__layout ${menuOpen ? "open" : "close"}`} style={{ paddingBottom: '6rem' }}>
          {sideMenu}
          <div className='top_bar' style={{ backgroundColor: '#252f3e' }}>
            {
              auth.authData.memberInfo &&

              <h2 className='title'>
                <SiteLink to={auth.authData.memberInfo && auth.authData.memberInfo.memberType === 99 ? "/member/admin/dashboard/" + auth.authData.memberInfo.clubInfo.clubId : "/member/dashboard"}>
                  <span> {auth.authData.memberInfo.clubInfo.clubName} </span>
                  <span> Virtual Store </span>
                </SiteLink>
              </h2>
            }
          </div>
          <div className='main_panel'>
            <div className='main_panel_inner'>
              <div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
};


function GetWelcomText({text}){

  const names = text.trim().split(' ');

  return(
    <h4>
       {'Welcome ' + names[0]}
    </h4>
  )
}

const DashboardLayoutRoute = ({ component: RenderComponent, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <MenuLoader />
          <RenderComponent {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;