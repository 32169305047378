import React from 'react';
import { Loading } from '../../../components';
import { useAuth } from 'auth';
import { ProductItems } from './productItems';

export function Products() {
    const auth = useAuth();
    const memberInfo = auth.authData.memberInfo;
    const user = auth.authData.user;
    const [sendingStatus, setSendingStatus] = React.useState("pending");
    
    React.useEffect(() => {

        setSendingStatus("pending");
        if (!memberInfo || memberInfo === null || !user || user === null) {
            return;
        }

        setSendingStatus(null);

        // eslint-disable-next-line
    }, [memberInfo, user]);

    if (sendingStatus === 'pending') {
        return <Loading />
    }
    return (
        <ProductItems categories={memberInfo.clubInfo.categoryId} clubId={memberInfo.clubId} />
    )
}