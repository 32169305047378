/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './orderDetailsStyle';
import React, { Fragment } from 'react';
import env from '../../../env';
import { Loading, ErrorPopup, useGet, Button } from '../../../components';
import { useRouter, usePost } from '../../../components';
import utils from '../../../utils';
import { useStore } from '../../../store';

export function OrderDetails() {
  const get = useGet();
  const { query } = useRouter();
  const o = query.o || '';

  React.useEffect(() => {
    get.send(env.apiBase + '/api/order/getPaidOrder/' + o);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }
  const order = get.response;
  
  return <Details order={order} />
}

function Details({ order }) {
  const paymentInfo = order.payment;
  const orderItems = order.orderItems.filter(x => x.orderItemType === 0);
  const taxLines = order.orderItems.filter(x => x.orderItemType === 1);
  const post = usePost();
  const { Redirect } = useRouter()
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const { dispatch } = useStore();

  function itemAmount(orderItem) {
    return (orderItem.unitPrice * 100 + (orderItem.addedUnitPrice ? orderItem.addedUnitPrice * 100 : 0) - (orderItem.discountUnitPrice ? orderItem.discountUnitPrice * 100 : 0)) / 100;
  }
  // function itemTotalAmount(orderItem) {
  //   return (orderItem.quantity * (orderItem.unitPrice * 100 + orderItem.addedUnitPrice * 100 - orderItem.discountUnitPrice * 100)) / 100;
  // }

  function reOrder() {
    let _cartId = env.getDevCartId();
    setSendingStatus('pending');
    post.send(env.apiBase + "/api/cart/reorder", {
      cartId: _cartId === 'null' ? null : _cartId,
      reOrderId: order.orderId
    });
  }

  if (post.hasErrors()) {

  }

  if (post.done() && sendingStatus === 'pending') {
    env.setDevCartId(post.response.results.cartInfo.cartId);
    setTimeout(() => {
      dispatch({type:'SET_STATE', payload : {cartInfo : post.response.results.cartInfo.totalQuantity === 0 ? 0 : (post.response.results.cartInfo.totalQuantity)-1}});
    }, 500);
    //dispatch({type:'SET_STATE', payload : {cartInfo : post.response.results.cartInfo.totalQuantity === 0 ? 0 : (post.response.results.cartInfo.totalQuantity)-1}});
    return <Redirect to={`/member/cart`} />
  }

  return (
    <Fragment>
      <div className='portal-title'> ORDER </div>
      <div>
        <Button onClick={reOrder}> REORDER </Button>
      </div>
      <div css={style.orderDetails}>
        <hr />
        <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
          <div className="col-md-12">
            <div><label css={style.fn}>Order Number:</label> <span css={style.fv}>{order.orderNo}</span> </div>
            <div><label css={style.fn}>Total Amount:</label> <span css={style.fv}><b>AU {utils.money.formatMoney(order.totalAmount)}</b></span> </div>
            <div><label css={style.fn}>Order Date:</label> <span css={style.fv}>{utils.date.formatYYYYMMDD(order.orderDate)}</span> </div>
            <div><label css={style.fn}>Payment:</label> <span css={style.fv}>{paymentInfo.paymentMethodText}</span> </div>
          </div>
        </div>
        <hr />

        <h3>ITEMS</h3>
        <div css={style.orderItems}>
          {orderItems.map((orderItem, i) => <div css={style.orderItem} className="row" key={orderItem.orderItemId}>
            <div className="col-6">
              <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
              <div dangerouslySetInnerHTML={{ __html: orderItem.itemDescHtml }}></div>
            </div>
            <div className="col-3">
              <div className="price">Unit Price: {utils.money.formatMoney(itemAmount(orderItem))}</div>
            </div>
            <div className="col-3">
              Qty: {orderItem.quantity}
            </div>
          </div>
          )}

          <div css={style.orderItem} className="row">
            <div className="col-9">
              Shipping Fee:
            </div>
            <div className="col-3">
              <div css={style.amount}>
                <b>{utils.money.formatMoney(order.shippingAmount)}</b>
              </div>
            </div>
          </div>

          {taxLines.map((orderItem, index) =>
            <div key={orderItem.orderItemId} css={style.orderItem} className="row">
              <div className="col-9">
                Tax: {orderItem.itemName}
              </div>
              <div className="col-3">
                <div css={style.amount}>
                  <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>
                </div>
              </div>
            </div>
          )}

          <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
            <div className="col-12">
              <div css={style.total}>
                Total: AU {utils.money.formatMoney(order.totalAmount)}
                {/* <span className="total-price">Item Total: {utils.money.formatMoney(order.itemAmount)}
                <span> + Shipping Fee: {order.shippingAmount > 0 ? 'AU ' + utils.money.formatMoney(order.shippingAmount) : 'FREE'}</span>
                <span> + GST: {utils.money.formatMoney(order.taxAmount)}</span>
              </span>
              <div className="grand-total">Grand Total (including GST): AU {utils.money.formatMoney(order.totalAmount)}
              </div> */}
              </div>
            </div>
          </div>

        </div>

        <hr />
        {/* <h3>CONTACT</h3> */}
        <h3>
          DELIVERY METHOD
        </h3>
        <div>
          {order.shippingMethod}
        </div>
        {
          (order.shippingAddress && order.shippingMethod === 'Standard') &&
          <Address addr={order.shippingAddress} clubName={order.clubName} />
        }
        <Billing billingAddr={order.billingAddress} useSameAddress={order.useSameAddress} clubName={order.clubName} />
        <h3> COMMENTS </h3>
        <div>
          {order.payment.ext.comments}
        </div>
      </div>
    </Fragment>
  )
}

function Address({ addr, clubName }) {
  return <div>
    <hr />
    <h3> SHIPPING ADDRESS </h3>
    <div><label css={style.fn}>Company:</label> <span css={style.fv}>{clubName}</span> </div>
    <div><label css={style.fn}>First Name:</label> <span css={style.fv}>{addr.firstName}</span> </div>
    <div><label css={style.fn}>Surame:</label> <span css={style.fv}>{addr.surname}</span> </div>
    <div>
      <label css={style.fn}>Address:</label>
      <span css={style.fv}>{addr.street1}</span>
      <span css={style.fv} style={{ marginLeft: '0.5rem' }}>{addr.street2}</span>
    </div>
    <div><label css={style.fn}>Suburb:</label> <span css={style.fv}>{addr.suburb}</span> </div>
    <div><label css={style.fn}>State:</label> <span css={style.fv}>{addr.state}</span> </div>
    <div><label css={style.fn}>Postcode:</label> <span css={style.fv}>{addr.postcode}</span> </div>
    <div><label css={style.fn}>Email Address:</label> <span css={style.fv}>{addr.email}</span> </div>
    <div><label css={style.fn}>Phone:</label> <span css={style.fv}>{addr.mobile}</span> </div>
  </div>
}

function Billing(props) {
  const billingAddr = props.billingAddr;
  const useSameAddress = props.useSameAddress;
  const clubName = props.clubName;
  return (
    <div>
      <hr />
      <h3> BILLING ADDRESS </h3>
      {
        useSameAddress === true &&
        <div> Use the same address </div>
      }
      {
        (billingAddr && useSameAddress === false) &&
        <Fragment>
          <div><label css={style.fn}>Company:</label> <span css={style.fv}>{clubName}</span> </div>
          <div><label css={style.fn}>First Name:</label> <span css={style.fv}>{billingAddr.firstName}</span> </div>
          <div><label css={style.fn}>Surame:</label> <span css={style.fv}>{billingAddr.surname}</span> </div>
          <div>
            <label css={style.fn}>Address:</label>
            <span css={style.fv}> {billingAddr.street1} </span>
            <span css={style.fv} style={{ marginLeft: '0.5rem' }}> {billingAddr.street2} </span>
          </div>
          <div><label css={style.fn}>Suburb:</label> <span css={style.fv}>{billingAddr.suburb}</span> </div>
          <div><label css={style.fn}>State:</label> <span css={style.fv}>{billingAddr.state}</span> </div>
          <div><label css={style.fn}>Postcode:</label> <span css={style.fv}>{billingAddr.postcode}</span> </div>
          <div><label css={style.fn}>Email Address:</label> <span css={style.fv}>{billingAddr.email}</span> </div>
          <div><label css={style.fn}>Phone:</label> <span css={style.fv}>{billingAddr.mobile}</span> </div>
        </Fragment>
      }
    </div>
  )
}

// eslint-disable-next-line
{/* <h4 style="margin-top:1.5em;">Delivery Method</h4>
<div>
  <div className="col-12">
    <div *ngIf="order.shippingMethod == 'Pickup'">
      <p style="margin: 1rem;"><b>Pickup</b></p>
      <div *ngIf="order.pickupLocation">
        <h5>Selected Location</h5>
        <div className="selected-pickup-location">
          <div><b>{{order.pickupLocation.companyName}}</b></div>
          <div>{{order.pickupLocation.suburb}}, {{order.pickupLocation.state}}</div>
          <div>T: {{order.pickupLocation.phone}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="order.shippingMethod != 'Pickup'">
      {{order.shippingMethod}}
    </div>

  </div>
</div>

<h4 style="margin-top:1.5em;">Shipping/Billing Information</h4>
<div className="addresses">

  <div className="row">
    <div className="col-6" *ngIf="order.shippingMethod != 'Pickup'">
      <h5>Shipping
        <a *ngIf="gmapShipping" [href]="gmapShipping" target="_blank"><i className="material-icons">place</i></a>
      </h5>
      <div className="fld"><label>First Name:</label> <span>{{order.shippingAddress.firstName}}</span> </div>
      <div className="fld"><label>Surname:</label> <span>{{order.shippingAddress.surname}}</span> </div>
      <div className="fld"><label>Email:</label> <span>{{order.shippingAddress.email}}</span> </div>
      <div className="fld"><label>Phone:</label> <span>{{order.shippingAddress.phone}}</span> </div>
      <div className="fld"><label>Address:</label> <span>{{order.shippingAddress.addressLine}}</span> </div>
      <div className="fld"><label>Suburb:</label> <span>{{order.shippingAddress.suburb}}</span> </div>
      <div className="fld"><label>State:</label> <span>{{order.shippingAddress.state}}</span> </div>
      <div className="fld"><label>Postcode:</label> <span>{{order.shippingAddress.postcode}}</span> </div>
    </div>
    <div className="col-6">
      <h5>Billing
        <a *ngIf="gmapBilling" [href]="gmapBilling" target="_blank"><i className="material-icons">place</i></a>
      </h5>
      <div *ngIf="order.useSameAddress">Use the same address</div>
      <div *ngIf="!order.useSameAddress">
        <div className="fld"><label>First Name:</label> <span>{{order.billingAddress.firstName}}</span> </div>
        <div className="fld"><label>Surname:</label> <span>{{order.billingAddress.surname}}</span> </div>
        <div className="fld"><label>Email:</label> <span>{{order.billingAddress.email}}</span> </div>
        <div className="fld"><label>Phone:</label> <span>{{order.billingAddress.phone}}</span> </div>
        <div className="fld"><label>Address:</label> <span>{{order.billingAddress.addressLine}}</span> </div>
        <div className="fld"><label>Suburb:</label> <span>{{order.billingAddress.suburb}}</span> </div>
        <div className="fld"><label>State:</label> <span>{{order.billingAddress.state}}</span> </div>
        <div className="fld"><label>Postcode:</label> <span>{{order.billingAddress.postcode}}</span> </div>

      </div>
    </div>
  </div>
</div>
<hr />
<h4 style="margin-top:1.5em;">Special Request</h4>
<div className="row">
  <div className="col-12">
    <div style="white-space:pre-line">
      {{order.specialRequest? order.specialRequest : "N/A"}}
    </div>
  </div>
</div> */}