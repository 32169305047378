/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
import { useAuth } from "auth";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const auth = useAuth();
  const memberInfo = auth.authData.memberInfo;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />

        {memberInfo && memberInfo.memberType === 99 ? (
          <SiteLink
            to={`/member/admin/dashboard/${memberInfo.clubInfo.clubId}`}
            css={[style.logo, scrolled && style.logoScrolled]}
          >
            <img css={style.logoImg} src='/assets/logos/logo.png' alt='logo' />
          </SiteLink>
        ) : (
          <SiteLink
            to='/'
            css={[style.logo, scrolled && style.logoScrolled]}
            onClick={backTop}
          >
            <img css={style.logoImg} src='/assets/logos/logo.png' alt='logo' />
          </SiteLink>
        )}

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
