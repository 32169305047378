/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import env from '../../../env';
import { Loading, ErrorPopup, usePost } from '../../../components';
import { useForm } from '../../../form'
//import { ProductTile } from './productTile';
//import { tileListStyle } from './productTileStyle';
import { ProductTile } from './productTileV2';
import { tileListStyle, portalStyle } from './productTileStyleV2';
import { getCategoryListModel } from './categoryListModel';
import { useStore } from '../../../store';
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";

function Portal({children}){
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
      let mount = document.getElementById("portal-root");
      mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductItems({ categories, clubId }) {
  
  const post = usePost();
  React.useEffect(() => {

    post.send(env.apiBase + "/api/product/getProducts", { 
      categories: categories,
      clubId : clubId
    });
    // eslint-disable-next-line
  }, [categories]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }
  const data = post.response;
  
  return data.items && data.items.length > 0 ?
    <Items items={data.items} clubCategories = {data.clubCategories} categories = {data.categories} defaultCategories = {categories} /> :
    <div>No products</div>;
}

function Items({ items, categories, defaultCategories, clubCategories }) {
  const s = tileListStyle;
  const defaultCategoriesArray = (defaultCategories && defaultCategories !== '') ? defaultCategories.split(',') : [];
  const model = getCategoryListModel(categories, defaultCategoriesArray, clubCategories);
  const categoryListForm = useForm(model, {usePlaceholder : true, onControlChanged: orderItemChanged});
  const { state } = useStore();
  const [openPopupMsg, setOpenPopupMsg] = useState("");
  const [showProducts, setShowProducts] = useState(()=> {
    sortProudcts(items);
    return items;
  });

  function orderItemChanged(e, control){
    if(control && control.name === 'categoryList'){
      
      let _items = [];
      if(control.value && control.value !== ''){
        _items = items.filter(item => {
          for(let i in item.productClubCategories){
            if(item.productClubCategories[i].clubCategoryId === control.value){
              //item.seqInCategory = item.productClubCategories[i].seq;
              return true;
            }
          }
          return false;
        });
        sortProudcts(_items);
      }
      else{
        _items = items;
      }

      //sort
      setShowProducts(JSON.parse(JSON.stringify(_items)));

    }
  };

  function openMsgPopUp(msg){
    setTimeout(() => {
      setOpenPopupMsg(msg);  
    }, 100);
    document.body.style.overflow = "hidden";
  }

  function closeMsgPopUp(){
    setOpenPopupMsg("");
    document.body.style.overflow = "unset";
  }

  const render = (name) => categoryListForm.renderControl(name, null);
  return (
    <Fragment>
      {
        (openPopupMsg && openPopupMsg !== "") &&
        <Portal>
          <div css={portalStyle.portal__background} className='portal__background' onClick={closeMsgPopUp}></div>
          <div css={portalStyle.portal__popupcontainer} className='portal__popupcontainer'>
            <div css={portalStyle.portal__error__msg} className="portal__error__msg"> ERROR <div className='close__button'><MdClose onClick={closeMsgPopUp} /></div></div>
            <div> {openPopupMsg} </div>
          </div> 
        </Portal>
      }
      <div className='portal-title'> PRODUCTS </div>
      <div css = {s.categoryList} className="portal">
        {render('categoryList')}
      </div>
      <div css={s.tiles} className="portal">
        {
          showProducts.length > 0 ? showProducts.map((item, index) => <ProductTile currency = {state.currency} key={index} product={item} openMsgPopUp = {openMsgPopUp} />)
          : <div style = {{margin: 'auto'}}> No product </div>
        }
      </div>
    </Fragment>
  )
}

function sortProudcts(items) {
  items.sort((a, b) => {
    return a.seqInCategory - b.seqInCategory;
  });
}