import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

const numberOfTiles = [1, 1, 1, 1]
//const marginLR = [14, 14, 14, 14]
//const marginTB = [14, 14, 14, 14]
const marginTB = [20, 20, 20, 20];

const widthPercent = (index) => 100 / numberOfTiles[index] + '%'

// const linkText = {
//   color: variables.fontColor,
//   fontSize: '0.8rem',
//   padding: '0 20px',
//   '&:hover': {
//     color: variables.fontColor,
//   }
// }

export const portalStyle = {
  portal__background: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9998'
  },
  portal__popupcontainer: mq({
    position: 'absolute',
    top: '12%',
    zIndex: '9999',
    display: 'inline-block',
    padding: '0.5rem 1.5rem',
    backgroundColor: 'rgb(37, 47, 62)',
    fontWeight: 700,
    color: 'white',
    borderRadius: '10px',
    left: '50%',
    transform: 'translateX(-50%)'
  }),
  portal__popupcontainer__centre: mq({
    position: 'absolute',
    top: '50%',
    zIndex: '9999',
    display: 'inline-block',
    padding: '0.5rem 1.5rem',
    backgroundColor: 'rgb(37, 47, 62)',
    fontWeight: 700,
    color: 'white',
    borderRadius: '10px',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }),
  portal__error__msg: {
    position: 'relative',
    '.close__button': {
      position: 'absolute',
      top: '50%',
      right: '0px',
      transform: 'translateY(-50%)'
    }
  }
}

export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth: ['800px'],
    margin: 'auto',
    '&.portal': mq({
      //maxWidth:'1024px',
      maxWidth: 'unset',
      margin: 'unset',
      marginLeft: '-10px',
      marginRight: '-10px'
    })
  }),
  categoryList: mq({
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth: ['800px'],
    margin: 'auto',
    '&.portal': {
      maxWidth: 'unset',
      margin: 'unset'
    },
    'div.ff.form-group': {
      maxWidth: '250px'
    }
  })
}

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexGrow: 0,
    flexShrink: 0,
  }),
  tileLink: {
    background: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    //padding: 5,
    //border: '1px solid red',
    '&:hover': {
      textDecoration: 'none'
      //background: '#f3f3f3'
    }
  },
  tileContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid',
    borderRadius: '10px',
    flexWrap: 'wrap',
    padding: '0.2rem'
    //border: '1px solid red'
  },
  prdImage: mq({
    backgroundColor: 'transparent',
    flex: ['0 0 100%', null, '0 0 25%'],
    maxWidth: ['100%', null, '25%'],
    padding: '0.2rem'
  }),
  bg: mq({
    //height: 0,
    //paddingBottom: '100%',
    //paddingTop: ['136px', null, '210px'],
    paddingTop: ['160px'],
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  }),
  name: mq({
    padding: '0.3rem',
    lineHeight: '1',
    fontSize: ['1rem', null,null,'1.2rem','1.4rem'],
    color: '#061f5c',
    //flex: '0 0 100%',
    //maxWidth: '100%',
    fontWeight: 700
  }),
  shortDes: css({
    //textAlign: 'center',
    //marginTop: '-10px',
    fontSize:'0.9rem',
    color : 'gray'
  }),
  nameWrap: mq({
    padding: '0.3rem',
    fontWeight: 'bold',
    //marginLeft: ['0rem', null, '1rem'],
    //marginRight: ['0rem', null, '1rem'],
    color: variables.fontColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: ['0 0 100%', null, '0 0 50%'],
    maxWidth: ['100%', null, '50%'],
    paddingLeft: ['1.2rem', null, '0.3rem']
  }),
  price: mq({
    padding: '0.3rem',
    //fontSize: ['1rem', null, '1rem'],
    fontSize: ['1rem', null,null,'1.2rem','1.4rem']
  }),
  qty: mq({
    padding: '0.3rem',
    display: 'flex',
    alignItems: 'center',
    '.qtyText': {
      flex: '0 0 30%',
      maxWidth: '30%'
    },
    '.qtyInput': {
      flex: '0 0 50%',
      maxWidth: '50%',
      '.form-group': {
        marginBottom: '0px'
      }
    }
  }),
  attributesWrap: mq({
    padding: '0.3rem',
    flex: ['0 0 100%', null, '0 0 27%'],
    maxWidth: ['100%', null, '27%'],
    fontWeight: 'bold',
    color: variables.fontColor
  }),
  button__wrap: mq({
    padding: '0.3rem',
    flex: ['0 0 100%', null, '0 0 25%'],
    maxWidth: ['1000%', null, '25%'],
    //border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems : 'center',
    'button': {
      // backgroundColor:'red'
    }
  }),

  button__view__product: mq({ 
    //marginBottom : '1.1rem',
    width: ['100%',null,'80%'],
    'a': {
      color: 'white',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  })

}