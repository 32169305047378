import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import DashboardLayoutRoute from 'layout/dashboardLayout';
import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';

//import { Login } from 'auth';
import { ChangePassword } from 'site/members/profile/changePassword';
import { ForgotPassword } from 'auth/controls/forgotPassword/forgotPassword';
import { ResetPassword } from 'auth/controls/resetPassword/resetPassword';
import { VerifyEmail } from 'auth/controls/verifyEmail/verifyEmail';
import { CreateAccountLoading } from 'auth/controls/createAccount/createAccount';
//import { MenuLoader } from './menuLoader';
import { Dashboard } from 'site/members/dashboard/dashboardPage';
import { Products } from 'site/members/products/products';
import { ProductView } from 'site/members/product/productView';
import { Cart } from 'site/members/cart/cart';
import { OrderDetails } from 'site/members/orderDetails/orderDetails';
import { OrderHistory } from 'site/members/orderHistory/orderHistory';

const MemberLayout = ({ children }) => (
  <div>

    {/* <MenuLoader /> */}
    <DashboardNoAuthLayoutRoute path='/member/forgotPassword' component={ForgotPassword} />
    <DashboardNoAuthLayoutRoute path='/member/resetPassword' component={ResetPassword} />
    <DashboardNoAuthLayoutRoute path='/member/verifyEmail' component={VerifyEmail} />
    <DashboardNoAuthLayoutRoute path='/member/createAccount' component={CreateAccountLoading} />

    <DashboardLayoutRoute path='/member/dashboard' component={Dashboard} />
    <DashboardLayoutRoute path='/member/changePassword' component={ChangePassword} />
    <DashboardLayoutRoute path='/member/products' component={Products} />
    <DashboardLayoutRoute path='/member/product/:slug' component={ProductView} />
    <DashboardLayoutRoute path='/member/cart' component={Cart} />
    <DashboardLayoutRoute path='/member/order-thankyou' component={OrderDetails} />
    <DashboardLayoutRoute path='/member/order-history' component={OrderHistory} />

    <DashboardLayoutRoute path='/member/admin/dashboard/:clubId' component={Dashboard} />
    <DashboardLayoutRoute path='/member/admin/products' component={Products} />
    <DashboardLayoutRoute path='/member/admin/product/:slug' component={ProductView} />
    <DashboardLayoutRoute path='/member/admin/cart' component={Cart} />

    <Route path='/member' exact>
      <Redirect to='/member/dashboard' />
    </Route>

    {/* <Route path="/">
      <Redirect to='/member/dashboard' />
    </Route> */}

  </div>
);
export default MemberLayout;