//import { mq, variables } from '../../../cssInJs';

export default{
  dashboard_des :{
    marginTop: '1rem'
  },
  dashboard__links : {
    fontWeight: 800
  },
  dashboard_li : {
    '&.admin' : {
      pointerEvents: 'none',
      'a' : {
        color: 'gray'
      }
    }
  }
}