/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './payStyle';
import React, { Fragment } from 'react';
import { usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useRouter } from '../../../components';
import { initStripe } from './payStripe';
import { CartSummary } from '../cartSummary/summary';
import { getPaymentFormData } from './paymentFormData';
import { useForm } from '../../../form';
import { useStore } from '../../../store';

export function Pay({ cart, model, form, payStripe }) {

  const [stripe, setStripe] = React.useState(null);
  const [card, setCard] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [sendingStatus, setSendingStatus] = React.useState(null);

  const post = usePost();
  const { Redirect } = useRouter();
  const paymentForm = useForm(getPaymentFormData(cart), { onControlChanged: orderItemChanged });
  const render = (name) => paymentForm.renderControl(name);
  const val = paymentForm.getValue();
  const { dispatch } = useStore();

  function orderItemChanged(e, control) {
    if (control && control.name === 'paymentMethod') {
      setError(null);
    }
  }

  function payNow(e) {

    paymentForm.validateForm(e, () => {
      if (error) return;
      
      stripe.createToken(card).then(function (result) {
        if (result.error) {
          setError(result.error.message);
        } else {
          setError(null);
          
          // Send the token to your server.
          //onSubmit(result.token.id, e);

          setSendingStatus('pending');
          var _value = paymentForm.getValue();
          dispatch({ type: 'SET_STATE', payload: { cartInfo: 0 } });
          post.send(env.apiBase + "/api/cart/payStripe", {
            tokenId: result.token.id,
            itemTotalAmount: cart.itemTotalAmount,
            cartId: env.getDevCartId(),
            comments: _value.comments
          });
        }
      });

    });


    // if (error) return;
    // stripe.createToken(card).then(function (result) {
    //   if (result.error) {
    //     setError(result.error.message);
    //   } else {
    //     setError(null);
    //     // Send the token to your server.
    //     onSubmit(result.token.id, e);
    //   }
    // });


  }

  // function onSubmit(tokenId) {
  //   setSendingStatus('pending');
  //   var _value = paymentForm.getValue();
  //   dispatch({type:'SET_STATE', payload : {cartInfo : 0}});
  //   post.send(env.apiBase + "/api/cart/payStripe", {
  //     tokenId: tokenId,
  //     itemTotalAmount: cart.itemTotalAmount,
  //     cartId: env.getDevCartId(),
  //     comments: _value.comments
  //   });
  // }

  // function onSubmit(tokenId, e) {
  //   paymentForm.validateForm(e, () => {
  //     setSendingStatus('pending');
  //     const _value = paymentForm.getValue();
  //     dispatch({ type: 'SET_STATE', payload: { cartInfo: 0 } });
  //     post.send(env.apiBase + "/api/cart/payStripe", {
  //       tokenId: tokenId,
  //       itemTotalAmount: cart.itemTotalAmount,
  //       cartId: env.getDevCartId(),
  //       comments: _value.comments
  //     });
  //   });
  // }

  function sendEmail(e) {
    paymentForm.validateForm(e, () => {
      setSendingStatus('pending');
      var _value = paymentForm.getValue();
      dispatch({ type: 'SET_STATE', payload: { cartInfo: 0 } });
      post.send(env.apiBase + "/api/cart/paycontactemail", {
        itemTotalAmount: cart.itemTotalAmount,
        cartId: env.getDevCartId(),
        comments: _value.comments
      });
    });

  }
  
  if (post.done()) {
    env.setDevCartId(null);
    return <Redirect to={`/member/order-thankyou?o=${post.response.results.OrderIdToken}`} />
  }

  return <div css={style.pay}>
    <div className="row" >
      <div className="col-md-9">
        <form>
          <h3>Payment Method</h3>
          {render('paymentMethod')}
        </form>
        {
          val.paymentMethod === 'card' &&
          <CreditCard setStripe={setStripe} setCard={setCard} setError={setError} />
        }
        <div className='pay__step__comments'>
          {render('comments')}
        </div>
      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>

    </div>

    <div css={style.formActions}>
      {
        val.paymentMethod === 'card' &&
        <Button css={style.button} onClick={payNow} className={`${sendingStatus === 'pending' ? 'pending' : ''}`} status={post.status}>Pay Now</Button>
      }
      {
        val.paymentMethod === 'cod' &&
        <Button css={style.button} onClick={sendEmail} className={`${sendingStatus === 'pending' ? 'pending' : ''}`} status={post.status}> Submit Order </Button>
      }
      <ErrorMessage errors={error} />
      <ErrorMessage errors={post.errors} />
    </div>

  </div>
}

function CreditCard({ setStripe, setCard, setError }) {

  React.useEffect(() => {
    const { stripe, card } = initStripe(setError)
    setStripe(stripe);
    setCard(card);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <h3>Pay by Credit Card</h3>
      <div css={style.stripeWrapper}>
        <div className="stripe-wrapper">
          <div id="card-element"></div>
        </div>
        <div css={style.paymentInfoText}>
          Payment through encrypted gateway can be made by Visa, Mastercard or AMEX.  Please enter your credit card details to complete your order.
          For alternative payment methods please
          <a href='tel:1300854461' className="contact-link"> Call Us </a>
        </div>
      </div>
    </Fragment>
  )
}

// function ContactEmail() {

//   return (
//     <Fragment>
//       <h3> Contact Email </h3>
//       <div css={style.stripeWrapper}>
//         <div css={style.paymentInfoText2}>
//           We will send you email
//           <div>
//             <SiteLink to="#contactForm" className="contact-link"> contact us </SiteLink>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   )
// }