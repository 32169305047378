/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./productViewStyle";
import utils from "../../../utils";
//import { useStore } from '../../../store';

export function ProductPrice({ product, currency, productPrice }) {
  //const { state } = useStore();
  //const country = state.country;
  const country = "AU";
  //console.log("dfsfsdf", product);

  // <b>{product.priceTextOveriding ? product.priceTextOveriding : 'AU ' + utils.money.formatMoney(product.priceIncTax)}</b>
  if (!product) return null;
  // priceAU: 29.4
  // priceExcTax: 26.7273
  // priceHelpText: null
  // priceIncTax: 29.4
  // priceNZ: 32.4
  // priceRetailAU: 49
  // priceRetailNZ: 54

  const price = (country === "NZ" ? product.priceNZ : productPrice) || 0;
  //const rrp = (country === "NZ" ? product.priceRetailNZ : product.priceRetailAU) || 0;
  //const discount = rrp ? (1.0 - price / rrp) * 100 : 0;
  //const rrpText = (country === "NZ" ? "NZ" : "AU") + " $" + rrp;
  const s = style.price;

  //const priceText = (country === 'NZ'? 'NZ': 'AU') + ' $' + price
  //const priceText = (country === 'NZ'? 'NZ': 'AU') + ' $' + utils.money.formatMoney(price, 2);
  //const priceText = (country === 'NZ'? 'NZ': 'AU') + utils.money.formatMoney(price, 2);
  let priceText =
    (country === "NZ" ? "NZ" : "AU") + utils.money.formatMoney(price, 2);
  if (
    currency === "NZD" &&
    product.productObject &&
    product.productObject.nzPrice
  ) {
    priceText =
      "NZ : " + utils.money.formatMoney(product.productObject.nzPrice, 2);
  }

  if (price === 0) return null;

  return (
    <div css={s.priceWrapper}>
      <div css={s.priceText}> {priceText} </div>
      {product.shippingFeeType === 1 && (
        <div css={s.priceText}>
          {"Shipping fee : AU " +
            (product.shippingFee
              ? utils.money.formatMoney(product.shippingFee, 2)
              : "$0")}
        </div>
      )}
    </div>
  );

  // if (rrp && rrp !== price) {
  //   return <div css={s.priceWrapper}>
  //     <div css={s.priceText}>{priceText} </div>
  //     <div css={s.rrpLine}><span css={s.rrpLabel}>RRP:</span> <span css={s.rrpPrice}>{rrpText}</span> <span css={s.rrpDiscount}>{discount.toFixed(0)}% discounted</span></div>
  //     {
  //       product.shippingFeeType === 1 &&
  //       <div css={s.priceText}>
  //         { 'Shipping fee : AU ' + (product.shippingFee ? utils.money.formatMoney(product.shippingFee, 2) : "$0")}
  //       </div>
  //     }
  //   </div>
  // } else {
  //   return <div css={s.priceWrapper}>
  //     <div css={s.priceText}> {priceText} </div>
  //     {
  //       product.shippingFeeType === 1 &&
  //       <div css={s.priceText}>
  //         { 'Shipping fee : AU ' + (product.shippingFee ? utils.money.formatMoney(product.shippingFee, 2) : "$0")}
  //       </div>
  //     }
  //   </div>
  // }
}
