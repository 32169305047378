import { fb, validators } from '../../../form';

export function getPaymentFormData(cart) {
    const data = cart || {}
    const paymentMethods = [
        { name: 'Credit Card', value: "card" }
    ];

    if(data.clubItem && data.clubItem.checkOnlyUseCard !== true){
        paymentMethods.push({ name: 'Invoice', value: "cod" });
    }
    
    const model = fb.group({
        paymentMethod: [data.paymentMethod || 'card', [], { label: null, type: 'radioList', options : paymentMethods, style : {inline : true} }],
        comments : [data.comments || '', [validators.Required()], { label: 'Purchase Order/Comments', type: 'textarea' }],
    });
    return model;
}