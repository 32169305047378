import variables from './cssInJs/variables';
import { getIdToken } from 'lib/auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints
}

const firebaseSettings = {
  apiKey: "AIzaSyCnywPGixvPsiJYTlCMFUgY-aEEU7XwziI",
  authDomain: "nelson-virtual-store.firebaseapp.com",
  projectId: "nelson-virtual-store",
  storageBucket: "nelson-virtual-store.appspot.com",
  messagingSenderId: "888988460144",
  appId: "1:888988460144:web:f9f616395cba8c78a000b5"
}

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  //console.log(token)
  if (token) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${token}`;
  }
};

export default {
  cssEmotion,
  firebaseSettings,
  apiNetJwt
}