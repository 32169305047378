/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import { useAuth } from 'auth';
import { Loading, SiteLink } from '../../../components';
import utils from 'utils';
import style from './dashboardPageStyle';
//import env from 'env';

//import { useGet } from 'components';
// import { SiteLink } from 'components';
//import { Loading, ErrorPopup } from 'components';

//import { SingleBannerPanel } from 'site/singleBanner/singlePanel';
//const r = utils.site.resourcePath;
//const bannerImage = '/sites/preventingharm/media/bwbanners/adobestock_392700489.jpg';

export function Dashboard() {
  const auth = useAuth();
  const memberInfo = auth.authData.memberInfo;
  const user = auth.authData.user;
  const [sendingStatus, setSendingStatus] = useState("pending");
  
  useEffect(() => { 
    setSendingStatus("pending");
    if(!memberInfo || memberInfo === null || !user || user === null){
      return;
    }

    setSendingStatus(null);
    
    // eslint-disable-next-line
  }, [memberInfo, user]);

  if(sendingStatus === 'pending'){
    return <Loading />
  }

  return (
    <DashBoard memberInfo = {memberInfo} />
  );
}

function DashBoard({memberInfo}){
  
  const bgUrl = memberInfo.clubInfo.thumbnailUrl;
  const alignV = '50%';
  const description = memberInfo.clubInfo.description;

  return(
    <Fragment>
      <div className='cms_item single-banner banner-item bannerSize--thin'
        style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY:alignV }}>
      </div>
      <div css={style.dashboard_des} className="dashboard_des" dangerouslySetInnerHTML={{ __html: description }}></div>
      <ul css={style.dashboard__links} className="dashboard__links">
        <li css={style.dashboard_li} className={`${memberInfo.memberType === 99 ? 'admin' : 'normal'}`}><SiteLink to="/member/order-history"> ORDERS </SiteLink></li>
        <li css={style.dashboard_li}><SiteLink to={memberInfo.memberType === 99 ? '/member/admin/products' : '/member/products' }> PRODUCTS </SiteLink></li>
        <li css={style.dashboard_li} className={`${memberInfo.memberType === 99 ? 'normal' : 'normal'}`}><SiteLink to="/member/cart"> CART </SiteLink></li>
      </ul>
    </Fragment>
  )
}