/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Loading, useGet, SiteLink } from '../../../components';
import { Table } from 'components/table/table';
import env from '../../../env';
import utils from 'utils';

export function OrderHistory(){

    const [sendingStatus, setSendingStatus] = React.useState("pending");
    const get = useGet();

    React.useEffect(() => {
        const url = `/api/order/getpaidorderlist`;
        get.send(env.apiBase + url);
    
        // eslint-disable-next-line
    }, []);

    const data = get.response;
    
    const columns = React.useMemo(
        () => [
            {
              Header: 'Order No.',
              accessor: 'orderNo',
              Cell: (c) => {
                //console.log(c)
                return (<SiteLink to={`/member/order-thankyou?o=${c.row.original.orderIdToken}`}><b>{c.cell.value}</b></SiteLink>)
              }
            },
            {
              Header: 'Payment',
              accessor: 'paymentMethodText',
            },
            {
              Header: 'Name',
              accessor: 'buyerName',
            },
            {
              Header: 'Email',
              accessor: 'buyerEmail',
            },
            {
              Header: 'Amount',
              accessor: 'totalAmount',
              Cell : (c) => "$" + c.cell.value
            },
            // {
            //   Header: 'Status',
            //   accessor: 'statusText',
            // },
            {
              Header: 'Order Date',
              accessor: 'orderDate',
              Cell: (c) => c.cell.value && utils.date.formatYYYYMMDD(c.cell.value)
            }
          ],
          []
    );

    if(get.done() && sendingStatus === 'pending'){
        setSendingStatus(null);
    }

    if(sendingStatus === 'pending'){
        return <Loading />
    }

    return(
        <Fragment>
          <div className='portal-title'> ORDERS </div>
          <div className="portal">
              <Table columns={columns} data={data} />
          </div>
        </Fragment>
    )
}