import { fb } from '../../../lib/form';
export function getCategoryListModel(data, defaultCategories, clubCategories) { 
    var options = [{name : 'All', value : ''}];
    if(clubCategories.length > 0){
      clubCategories.forEach(el => {
        options.push({name : el.categoryName, value : el.clubCategoryId});
      });
    }

    // let categoryList = "";
    // if(defaultCategories.length === 1){
    //   categoryList = defaultCategories[0];
    // }

    const model = fb.group({
      categoryList: ['', [], {
        label: 'FILTER BY',
        type: 'select',
        options : options
     }]
    }); 
    return model;
}